.food-card{
  @include border-primary;
  background: var(--color-card);
  position: relative;
  min-height: 8rem;
  width: 22.0625rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  .food-card-img{
    @include border-primary;
    @include center-b;
    background: var(--color-img-bg);
    min-width: 8rem;
    min-height: 8rem;
    img{
      max-width: 6rem;
      max-height: 6rem;
    }
  }
  .food-card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    color: var(--color-white);
    .food-card-header{
      div{
        @include text-primary-h2;
      }
    }
    .food-card-footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .food-card-price{
        @include text-primary-h2;
        color: var(--color-white);
      }
      .food-card-add-to-cart-container{
        display: flex;
      }
    }
  }
}