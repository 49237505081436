.label{
  color: var(--color-white);
  @include text-primary-h4;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
}

.select{
  @include border-primary;
  @include text-primary-h4;
  border: var(--color-grey) 0.0625rem solid;
  background: rgba($color: #000000, $alpha: 0);
  color: var(--color-white);
  height: 2rem;
  width: 13.25rem;
  &:focus{
    outline: none;
  }
  option{
    background: var(--color-card);
    height: 2.5rem;
    width: 13.25rem;
  }

  &.number{
    width: 2.5rem;
    margin-right: 0.5rem;
    .option{
      width: 2.5rem;
    }
  }
}