:root{
  --color-primary: #D33A3A;
  --color-primary-dark: #AF2727;
  --color-white: #F6F6F6;
  --color-light-grey: #D4D4D4;
  --color-grey: #727272;
  --color-dark: #15141C;
  --color-card: #110F18;
  --color-img-bg: #0A0910;
  --color-button-disabled: #181720;
}