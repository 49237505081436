.choice-modal{
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.95;
  color: var(--color-white);
  transition: background 1s ease;
  @include center-b;
  .choice-modal-close-container{
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 4rem;
    top: 4rem;
    cursor: pointer;
    @include center-b;
    img{
      width: 1rem;
      height: 1rem;
    }
  }
  .choice-modal-container{
    position: relative;
    width: 90vw;
    @include center-b;
    flex-direction: column;
    .choice-modal-title{
      @include text-secondary-hero
    }
    .choice-modal-option-container{
      display: flex;
      flex-direction: column;
    }
    .choice-modal-price-container{
      display: flex;
      justify-content: space-between;
      @include text-primary-h3;
      width: 13.25rem;
      margin-top: 1rem;
    }

    .choice-modal-button{
      margin-top: 1rem;
      width: 11.75rem;
    }
    .choice-modal-close-text{
      width: 11.75rem;
      @include text-primary-h3;
      cursor: pointer;
    }
  }
}