// Center both direction
@mixin center-b{
  display: flex;
  justify-content: center;
  align-items: center;
}

// Center horizontal
@mixin center-h{
  display: flex;
  justify-content: center;
}

// Center vertical
@mixin center-v{
  display: flex;
  align-items: center;
}