// Primary font

@mixin text-primary-font{
  font-family: 'Work Sans';
  &::selection{
    background: var(--color-primary);
    color: var(--color-white);
  }
}

@mixin text-primary-h1{
  @include text-primary-font;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.5rem;
}

@mixin text-primary-h2{
  @include text-primary-font;
  font-weight: 600;
  font-size: 1rem;
}

@mixin text-primary-h3{
  @include text-primary-font;
  font-weight: 600;
  font-size: 0.875rem;
}

@mixin text-primary-h4{
  @include text-primary-font;
  font-weight: 500;
  font-size: 0.875rem;
}


// Secondary font
@mixin text-secondary-font{
  font-family: 'Brandon Grotesque';
  &::selection{
    background: var(--color-primary);
    color: var(--color-white);
  }
}

@mixin text-secondary-hero{
  @include text-secondary-font;
  font-size: 3rem;
}

@mixin text-secondary-h1{
  @include text-secondary-font;
  font-size: 1.75rem;
}

@mixin text-secondary-h2{
  @include text-secondary-font;
  font-size: 1.125rem;
}

@mixin text-secondary-h3{
  @include text-secondary-font;
  font-size: 1rem;
}

@mixin text-secondary-h4{
  @include text-secondary-font;
  font-size: 0.875rem;
}
