.button-plus-primary{
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 1.25rem;
  bottom: 1.25rem;
  @include center-b;
  @include border-primary;
  background: var(--color-primary);
  cursor: pointer;
  transition: transform 0.2s ease;
  &:hover{
    transform: scale(1.1);
  }
  &:active{
    transform: scale(0.8);
  }
  .button-plus{
    position: relative;
    transition: transform 0.3s ease;
    @include center-b;

    @mixin button-plus-plus-add{
      background: var(--color-white);
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
    .button-plus-add-vertical{
      @include button-plus-plus-add;
      height: 1rem;
      width: 0.125rem;
    }
    .button-plus-add-horizontal{
      @include button-plus-plus-add;
      width: 1rem;
      height: 0.125rem;
    }
  }
}