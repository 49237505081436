.cart-card{
  @include border-primary;
  width: 303px;
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  background: var(--color-card);
  padding: 1.5rem;
  margin-left: 2rem;

  &.scroll{
    position: fixed;
  }

  .cart-card-header{
    @include text-secondary-h3;
    margin: 0 0 0.625rem 0;
  }
  .cart-card-items-container{
    max-height: calc(100vh - 340px);
    overflow-y: auto;
    padding: 0 4px;
    .cart-card-body{
      .cart-card-item-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .cart-card-item-header-left{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .cart-card-item-name{
            @include text-primary-h2;
          }
        }
        .cart-card-item-header-right{
          .cart-card-item-price{
            @include text-primary-h4;
          }
        }
      }
      .cart-card-item-footer{
        .cart-card-item-choice{
          margin: 0;
          padding-left: 56px;
          transform: translateY(-6px);
          @include text-primary-h4;
          color: var(--color-grey);
          list-style: none;
        }
      }
    }
  }


  .cart-card-footer{
    .cart-card-total{
      display: flex;
      justify-content: space-between;
      margin: 1.625rem 0 0.75rem 0;
      div{
        @include text-primary-h2;
      }
    }
    .cart-card-button{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .cart-card-footer-spacing{
        margin-bottom: 12px;
      }
    }
  }
}

// Media query
@media screen and (max-width: 1180px){
  .cart-card{
    margin-left: 0rem;
    .cart-card-items-container{
      max-height: calc(100vh - 389px);
    }
  }
}