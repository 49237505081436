// Import Global
@import 'scss/global/fonts.scss';
@import 'scss/global/texts.scss';
@import 'scss/global/colors.scss';
@import 'scss/global/borders.scss';


// Import Mixins
@import 'scss/mixins/center.scss';


// Import Components
@import 'scss/components/navbar.scss';
@import 'scss/components/footer.scss';
@import 'scss/components/button.scss';
@import 'scss/components/select.scss';
@import 'scss/components/button-plus.scss';
@import 'scss/components/menu-card.scss';
@import 'scss/components/food-card.scss';
@import 'scss/components/cart-card.scss';
@import 'scss/components/choice-modal.scss';


// Import Pages
@import 'scss/pages/home.scss';

*{
  .unselectable, img{
    -webkit-touch-callout: none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
    pointer-events: none;
  }
}

body {
  background: var(--color-dark);
  background-image: url(svg/bg.svg);
  background-attachment: fixed;
  margin: 0;
}