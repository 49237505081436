.button-primary{
  @include text-primary-h4;
  @include border-primary;
  @include center-b;
  background: var(--color-primary);
  color: var(--color-white);
  height: 2.5rem;
  padding: 0 12px;
  cursor: pointer;
  transition: transform 0.2s ease;
  user-select: none;
  text-decoration: none;
  &:hover{
    transform: scale(1.05);
  }
  &:active{
    transform: scale(0.95);
  }
  &.small{
    height: 2rem !important;
  }
  &.disabled{
    background: var(--color-button-disabled);
  }
  &.invisible{
    background: rgba($color: #000000, $alpha: 0.0);
  }
}