.navbar-container{
  @include center-v;
  flex-direction: column;
  overflow: hidden;
  .navbar-logo{
    @include center-h;
    height: 12.5rem;
  }
  .navbar-space-filler{
    display: none;
    width: 95vw;
    &.active{
      display: block;
    }
  }
  .navbar{
    width: 100vw;
    z-index: 1005;
    transition: all 0.5s ease;
    &.scroll{
      background: linear-gradient(0deg, rgba(21,20,28,0) 0%, rgba(21,20,28,0.5102415966386555) 30%, rgba(21,20,28,1) 100%);
      position: fixed;
      top: 0;
    }
    @include center-b;
    padding: 1rem 0;
    margin: 0;
    // react horizontal menu scrolling module
    .menu-item-wrapper{
      outline: none;
    }
    .scroll-menu-arrow{
      display: none;
      position: relative;
      right: 0;
      padding: 0.5rem;
      color: var(--color-white);
      @include text-secondary-h3;
      cursor: pointer;
      div{
        position: absolute;
        transform: translate(-50%, -55%);
        z-index: 500;
      }
      .arrow-next{
        left: -50%;
        padding-left: 2rem;
        background: linear-gradient(90deg, rgba(21,20,28,0) 0%, rgba(21,20,28,1) 50%, rgba(21,20,28,1) 100%);
      }
      .arrow-prev{
        left: 150%;
        padding-right: 2rem;
        background: linear-gradient(90deg, rgba(21,20,28,1) 0%, rgba(21,20,28,1) 50%, rgba(21,20,28,0) 100%);
      }
    }
    .horizontal-menu{
      width: 95vw;
      justify-content: center;
      .menu-wrapper{
        width: 95vw;
      }
    }

    li{
      list-style: none;
      padding: 1rem 0;
      color: var(--color-light-grey);
      transition: 0.3s color ease;
      :hover{
        color: var(--color-white);
      }
      a{
        @include text-secondary-h3;
        transition: 0.3s color ease;
        cursor: pointer;
        padding: 1rem;
      }
    }
  }
}


// Media query
@media screen and (max-width: 1180px){
  .scroll-menu-arrow{
    display: block !important;
  }
}

@media screen and (max-width: 767px){
  .navbar{
    .scroll-menu-arrow{
      .arrow-next{
        background: rgba(0, 0, 0, 0) !important;
      }
      .arrow-prev{
        background: rgba(0, 0, 0, 0) !important;
      }
    }
  }
}