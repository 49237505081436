.menu-card{
  @include border-primary;
  position: relative;
  min-height: 14.375rem;
  width: 46.0625rem;
  display: flex;
  background: var(--color-card);
  margin-bottom: 2rem;

  // Header
  .menu-card-head{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 10rem;
    padding: 1.25rem 1.25rem;

    // Food
    .menu-card-food{
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -2rem);
    }

    // Triangle
    .menu-card-triangle{
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: 0.5rem;
      overflow: hidden;
    }

    // Title
    .menu-card-title{
      @include text-secondary-h3;
      line-height: 1.25rem;
      letter-spacing: 0.0625rem;
      z-index: 100;
      color: var(--color-white);
    }
    .menu-card-subtitle{
      @include text-secondary-h1;
      line-height: 1.375rem;
      letter-spacing: 0.0625rem;
      z-index: 100;
      color: var(--color-white);
    }

    // Price
    .menu-card-price{
      position: absolute;
      bottom: 1.25rem;
      left: 1.25rem;
      color: var(--color-white);
      @include text-primary-font;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }


  // Body
  .menu-card-body{
    position: relative;
    display: flex;
    padding: 1.25rem;
    // Category
    .menu-card-category-container{
      margin-right: 2.5rem;
      .menu-card-category{
        color: var(--color-white);
        @include text-primary-h2;
        margin: 0 0 0.5rem 0;
      }
      .menu-card-list{
        padding: 0;
        margin: 0;
        display: inline-block;
        li{
          white-space: nowrap;
          line-height: 1.375rem;
          color: var(--color-grey);
          @include text-primary-h3;
          list-style: none;
        }
      }
    }
  }
}


// Media query
@media screen and (max-width: 767px){
  .menu-card{
    flex-direction: column;
    width: 22.0625rem;
    height: auto;
    // Header
    .menu-card-head{
      min-height: 5rem;

      // Food
      .menu-card-food{
        left: 75%;
        top: 4rem;
        transform: translate(-50%, 0);
      }
    }
    .menu-card-body{
      flex-direction: column;
      .menu-card-category-container{
        margin-bottom: 1.5rem;
      }
    } 
  }
}