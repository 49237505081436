.home-container{
  width: 70rem;
  margin: 0 auto;
  .home-information-container{
    color: var(--color-white);
    .home-information-title{
      @include text-primary-h2;
      text-decoration: underline;
    }
    .home-information-text{
      @include text-primary-h4;
      margin: 0.25rem 0 2rem 0;
      line-height: 22px;
    }
    a{
      color: var(--color-white);
    }
  }


  .home-section-title{
    @include text-secondary-h1;
    color: var(--color-white);
    padding: 1rem 0;
  }
  .home-body-container{
    display: flex;
    .home-body{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .home-food-card-container{
        width: 46.0625rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .home-cart{
      
    }
  
    .home-cart-button{
      display: none;
      z-index: 1001;
      min-width: 327px;
      position: fixed;
      bottom: 1rem;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

// Scroll bar
  /* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-img-bg); 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-dark); 
  cursor: pointer;
}




// Media query
@media screen and (max-width: 1180px){
  .home-container{
    @include center-h;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .home-information-container{
      margin: 0 0.5rem;
    }
    .home-body-container{
      .home-body{
        transition: filter 0.25s ease;
        &.blur{
          filter: blur(0.5rem);
        }
      }
      .home-cart{
        position: fixed;
        bottom: 4.5rem;
        left: 50%;
        transform: translate(-50%, 50%) scale(0);
        box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.25);
        z-index: 1000;
        // opacity: 0;
        transition: all 0.25s ease;
        will-change: transform;
        &.active{
          opacity: 1;
          transform: translate(-50%, 0) scale(1);
        }
      }
      .home-cart-button{
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .home-container{
    @include center-h;
    width: 100%;
    .home-body-container{
      .home-body{
        width: 90vw;
        @include center-v;
        .home-food-card-container{
          width: 90vw;
          @include center-b;
          flex-direction: column;
          flex-wrap: initial;
        }
      }
    }
  }
}